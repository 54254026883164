'use client'

import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import uuid from 'uuid'

import NotificationPortal from 'components/NotificationPortal'

import { State as AppState } from 'state/types'

import * as selectors from 'state/notifications/selectors'
import { actions } from 'state/notifications/slice'

const mapStateToProps = (state: AppState) => ({
  currentlyShowingNotificationId: selectors.getCurrentlyShowingNotificationId(state),
})

const mapActionsToProps = {
  showNotification: actions.showNotification,
  hideNotification: actions.hideNotification,
}

const connector = connect(mapStateToProps, mapActionsToProps)

export enum CloseType {
  CloseButtonClick = 'close_button_click',
  BodyClick = 'body_click',
  Auto = 'auto',
}

type OwnProps = {
  onNotificationClose?: ((closeType: CloseType | undefined) => void) | null
}

type Props = OwnProps & ComponentProps<typeof NotificationPortal> & ConnectedProps<typeof connector>

type State = {
  id: string
}

class Notification extends Component<Props, State> {
  state = {
    id: uuid.v4(),
  }

  componentDidMount() {
    this.showNotification()
  }

  componentWillUnmount() {
    this.hideNotification()
  }

  hideNotification = () => this.props.hideNotification({ id: this.state.id })

  showNotification = () => this.props.showNotification({ id: this.state.id })

  handleNotificationClose = (closeType: CloseType | undefined) => {
    this.hideNotification()
    this.props.onNotificationClose?.(closeType)
  }

  render() {
    if (this.props.currentlyShowingNotificationId === this.state.id) {
      return <NotificationPortal {...this.props} onClose={this.handleNotificationClose} />
    }

    return null
  }
}

export { Notification }

export default connector(Notification)
