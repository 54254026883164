'use client'

import { useEffect, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { urlWithParams } from 'libs/utils/url'

import { State as AppState } from 'state/types'
import * as actions from 'state/faq-entry/actions'
import * as selectors from 'state/faq-entry/selectors'

import { AccessChannel } from 'constants/index'
import { FaqEntryType } from 'constants/faq-entry'

type Props = {
  accessChannel?: AccessChannel
  type: FaqEntryType
  render: (faqUrl: string, faqEntryId: number) => ReactNode
}

const FaqEntryUrl = ({ type, render, accessChannel }: Props) => {
  const dispatch = useDispatch()

  const faqEntry = useSelector((state: AppState) => selectors.getFaqEntryByType(state, type))

  useEffect(() => {
    dispatch(actions.fetchFaqEntryByTypeRequest({ type }))
  }, [type, dispatch])

  if (!faqEntry?.url) return null

  const url = accessChannel
    ? urlWithParams(faqEntry.url, { access_channel: accessChannel })
    : faqEntry.url

  return <>{render(url, faqEntry.id)}</>
}

export default FaqEntryUrl
